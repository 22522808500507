import React, { useState, useRef } from 'react'
import styled from 'styled-components'
//import CustomButton from '../components/CustomButton'
//import CustomForm from '../components/CustomForm'
import Layout from '../components/Layout'
import MembershipPackage from '../components/MembershipPackage'
import { useStaticQuery, graphql } from "gatsby"
import Resizer from "react-image-file-resizer";
import BGRegistration from "../images/bg_registracija.jpg"


const Signup = () => {

    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [hasWeaponNumber, setHasWeaponNumber] = useState(false);


    const packageRef = useRef(null);

    const packagePicked = (name) => {
        packageRef.current.value = name;
        document.querySelector('.reg-form').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    const resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
            uri => {
                resolve(uri);
            }, 'file');
    });

    const handleFormWithImageSubmit = async (event) => {
        event.preventDefault();

        const form = event.target;
        let formData = new FormData(form);
        const resizedImg = await resizeFile(formData.get("slika"));
        formData.set("slika", resizedImg)

        fetch("/", {
            body: formData,
            method: "POST",
        })
            .then(() => {
                console.log("Form successfully submitted")
                setIsButtonVisible(false);
                setShowMessage(true);
                setResponseMessage("Hvala Vam na registraciji, bićete kontaktirani uskoro.")
            })
            .catch((error) => {
                console.log(error);
                setShowMessage(true);
                setResponseMessage("Došlo je do greške. Pokušajte ponovo.")
            });
    };

    // Not used at the moment
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        console.log(e.target);

        const form = e.target;
        let formData = new FormData(form);
        const resizedImg = await resizeFile(formData.get("slika"));
        formData.set("slika", resizedImg)
        console.log(formData);

        fetch("/", {
            method: "POST",
            //headers: { "Content-Type": "application/x-www-form-urlencoded" },
            headers: { "Content-Type": "multipart/form-data" },
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => console.log("Form successfully submitted"))
            .catch((error) => alert(error));
    }

    const handleCheckboxClicked = (e) => {
        e.preventDefault()
        console.log("clicked");
        console.log(e.target.checked);
        setHasWeaponNumber(e.target.checked);
    }


    const pageDataQuery = graphql`
    query {
    contentfulSignUpSadrzaj {
      paketInfo {
        boja
        benefitiLista {
          tekst
        }
        cijena
        period
        naziv
      }
      signupTitle,
      tekstPotvrda {
        tekstPotvrda
      }
    }
  }
    `


    const data = useStaticQuery(pageDataQuery);
    console.log(data);
    return (
        <Layout activeTab="Registration" bgImage={BGRegistration}>
            <MainContainer>
                { 
                    !showMessage &&
                <>
                    <CardsContainer>
                        {
                            data.contentfulSignUpSadrzaj.paketInfo.map((item, index) => {
                                return (
                                    <MembershipPackage
                                        clicked={packagePicked}
                                        tierName={item.naziv}
                                        price={item.cijena}
                                        period={item.period}
                                        color={item.boja}
                                        benefits={item.benefitiLista}></MembershipPackage>
                                )
                            })
                        }
                        {/* <MembershipPackage clicked={packagePicked} tier='bronze' priceMonth='20'></MembershipPackage>
                    <MembershipPackage tier='silver' priceMonth='50'></MembershipPackage>
                    <MembershipPackage tier='gold' priceMonth='70'></MembershipPackage> */}
                    </CardsContainer>
                    <div className="reg-form"></div>
                    <br />
                    <br />
                    <CustomFormContainer>
                        <h3 style={{ color: '#ffffff' }}>Registracijska forma</h3>
                        <form className="customForm" onSubmit={handleFormWithImageSubmit} method="post" name="registracija" data-netlify="true" netlify-honeypot="bot-field">
                            <input type="hidden" name="form-name" value="registracija" />
                            <CustomFormInput>
                                <label for="name">Ime i prezime *</label>
                                <input required name="ime" id="name" ></input>
                            </CustomFormInput>
                            {/* <CustomFormInput>
                            <label for="birth" >Datum rodjenja *</label>
                            <input required name="datumRodjenja" id="birth" type="date" ></input>
                        </CustomFormInput> */}
                            <CustomFormInput>
                                <label for="idnumber" >Broj lične karte *</label>
                                <input required name="brojLicneLarte" id="idnumber" type="text" ></input>
                            </CustomFormInput>
                            {/* <br/>
                        <div>
                            <input onChange={(e) => handleCheckboxClicked(e)} className="form-check-input" checked={hasWeaponNumber} type="checkbox" id="flexCheckDefault"/>
                            <label style={{color: "#ffffff", marginLeft: "10px"}} className="form-check-label" htmlFor="flexCheckDefault">Imam oružni list</label>
                        </div>
                        <br/> */}

                            {
                                true &&
                                <CustomFormInput>
                                    <label for="weaponid" >Broj oružnog lista (ukoliko ga posjedujete)</label>
                                    <input name="brojOruznogLista" id="weaponid" type="text" ></input>
                                </CustomFormInput>
                            }

                            <CustomFormInput>
                                <label for="email">Email *</label>
                                <input required name="email" id="email" type="email" ></input>
                            </CustomFormInput>
                            <CustomFormInput>
                                <label for="adr">Adresa stanovanja *</label>
                                <input required name="adresaStanovanja" id="adr" type="text" ></input>
                            </CustomFormInput>
                            <CustomFormInput>
                                <label for="tel">Kontakt telefon *</label>
                                <input required name="kontaktTelefon" id="tel" type="text" ></input>
                            </CustomFormInput>
                            <CustomFormInput>
                                <label for="package">Paket *</label>
                                <select ref={packageRef} name="paket" id="package" >
                                    {
                                        data.contentfulSignUpSadrzaj.paketInfo.map((item, index) => {
                                            return (
                                                <option value={item.naziv}>{item.naziv}</option>
                                            )
                                        })
                                    }
                                    {/* <option value="Starter">Starter</option>
                                <option value="Standard">Standard</option>
                                <option value="Premium">Premium</option> */}
                                </select>
                            </CustomFormInput>
                            <CustomFormInput>
                                <label for="img">Slika *</label>
                                <input style={{ color: "#ffffff" }} required name="slika" id="img" type="file" accept="image/*" ></input>
                            </CustomFormInput>
                            {
                                isButtonVisible &&
                                <>
                                    <h5 className="confirmation-text">{data.contentfulSignUpSadrzaj.tekstPotvrda.tekstPotvrda}</h5>
                                    <CustomButton type="submit">ZAVRŠI REGISTRACIJU</CustomButton>
                                </>
                            }
                        </form>
                    </CustomFormContainer>
                    <br />
                </>
                }

                {
                    showMessage &&
                    responseMessage != '' &&
                    <SuccessMessageContainer>
                        <h3>{responseMessage}</h3>
                    </SuccessMessageContainer>
                }

                {/* <CustomButton type="submit" form="registrationForm">ZAVRSI REGISTRACIJU</CustomButton> */}

            </MainContainer>
        </Layout>
    )
}
export default Signup

const MainContainer = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    background: rgba(70, 67, 70, 0.6);
    border-radius: 0.625rem;
`

const SuccessMessageContainer = styled.div`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    h3{
        color: #ffffff;
        font-family: 'Poppins';
        align-self: center;
    }
`

const CardsContainer = styled.div`
    /* padding: 40px;
    background: rgba(70, 67, 70, 0.6);
    border-radius: 10px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;

`

const CustomButton = styled.button`
    //margin-top: 1rem;
    align-self: center;
    max-width: 30rem;
    background-color: rgba(70, 67, 70, 0.6);
    box-shadow: 0 0.1875rem 0.625rem rgb(0 0 0 / 0.5);
    height: 3.75rem;
    width: 100%;
    color: #ffffff;
    border: 1px solid #000000;
`

const CustomFormContainer = styled.div`
    .customForm{
        display: flex;
        flex-direction: column;
        .confirmation-text{
            color: #ffffff;
            font-weight: bold;
            font-family: 'Poppins';
            margin-bottom: 2rem;
            
        }

        .response-msg{
            color: #ffffff;
            align-self: center;
        }
    }
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
`
const CustomFormInput = styled.div`
    flex: 1;
    min-width: 70vw;
    font-family: 'Poppins';
    @media only screen and (min-width: 1500px) {
        min-width: 55vw;
    }
    @media only screen and (min-width: 1200px) {
        min-width: 65vw;
    }
    margin-bottom: 1rem;
    //max-width: 50vw;

    select{
        color: #000000;
    }
    input, select{
        width: 100%;
        height: 4rem;
        padding: 1rem;
    }

    input, label{
        display: block;
        font-family: 'Poppins';
    }

    label{
        color: #ffffff;
    }
    
`

const CustomFormCheckbox = styled.div`
    label{
        color: #ffffff;
        margin-left: 10px;
    }
`




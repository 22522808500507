import React from 'react'
import styled from 'styled-components'
//import CustomButton from './CustomButton'

const MembershipPackage = ({clicked, tierName, color, price, benefits, period}) => {

    // let tierColor = '#000000';
    // let tierName = 'STANDARD'

    // switch (tier) {
    //     case "bronze":
    //         tierColor = '#241C1C'
    //         tierName = "Starter"
    //         break;
    //     case "silver":
    //         tierColor = '#8C8C94'
    //         tierName = "Silver"
    //         break;
    //     case "gold":
    //         tierColor = '#E4C164'
    //         tierName = "Gold"
    //         break;
    //     default:
    //         break;
    // }
    const handlePackageClick = (name) => {
        if(clicked){
            clicked(name);
        }
    }

    const CardContainer = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-even;
        align-items: center;
        height: 34.25rem;
        width: 18.75rem;
        background: #ffffff;
        border-top: 1.25rem solid ${color};
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        border-radius: 1.25rem 1.25rem 0px 0px;
        box-shadow: 0 0.1875rem 0.625rem rgb(0 0 0 / 0.5);
    
`

  return (
    <CardContainer>
            <br />
            <br />
            <CardTitle>{tierName.toString().toUpperCase()}</CardTitle>
            <br />
            <CardPriceText>{price} KM/{period}</CardPriceText>
            <br />
            <br />
            <CardTextContainer>
                    {
                    benefits.map((item, index) => {
                        return(
                            <p key={index}> - {item.tekst}</p>
                        )
                    })}
                    {/* <p>- test1</p>
                    <p>- test1</p>
                    <p>- test1</p>
                    <p>- test1</p> */}
            </CardTextContainer>
            <br />
            <CustomButton onClick={() => handlePackageClick(tierName)}>IZABERI</CustomButton>
            <br/>
    </CardContainer>
  )
}

export default MembershipPackage



const CardBody = styled.div`
    
`

const CustomButton = styled.button`
    border: 1px solid black;
    color: #000000;
    background-color: #ffffff;
    margin-top: auto;
    height: 3.75rem;
    width: 70%;
`

const CardTitle = styled.h4`
    //font-weight: bold;
    
`

const CardPriceText = styled.h2`
    font-weight: bold;
    font-family: 'Poppins';
`


const CardTextContainer = styled.div`
    display: inline-block;
  //margin-right: 1.5rem;
  vertical-align: top;
    p{
        text-align: start;
        word-break: ;
        line-break: auto;
        font-family: 'Poppins';
    }
    
`

// const CardText = styled.ul`
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
//     > li{
//         padding-left: 10px;
//         padding-right: 10px;
//     }
    
// `